import { useEffect, useState } from 'react';
import { useCountDown } from './hooks/useCountDown';
import corr from './images/check-mark.png'
import incorr from './images/incorrect.png'
import './index.scss';



function GreetingTest({ onTestModeChange, onTestModeChangeBar, onUserNameChange, onUserJobTitle, onTestDateChange }) {



  return (
    <div className='greeting'>
      <h1>COMPLIMENTO!</h1>
      <h2> Аттестация "Кухня" </h2>

      <form >
        <ul>
          <li><span>ФИО</span> <input className='n' type="text" name="fullName" placeholder="Введите ваше ФИО" required onChange={(evt) => onUserNameChange(evt.target.value)} /></li>
          <li><span>Должность</span> <input className='n' type="text" name="jobTitle" placeholder="Введите вашу должность" required onChange={(evt) => onUserJobTitle(evt.target.value)} /></li>
          <li><span>Дата прохождения теста</span> <input className='n' type="date" name="data" placeholder="Введите дату" required onChange={(evt) => onTestDateChange(evt.target.value)} /></li>
        </ul>
       <div className='greeting-btns'>
          <button onClick={onTestModeChange}>Меню - Кухня</button>
          <button onClick={onTestModeChangeBar}>Меню - Бар</button>
       </div>
      </form>

      <h4> Внимание! </h4>
      <p>Тест "Меню - Кухня" ограничен по времени 60 минут!</p>
      <p> Тест "Меню - Бар" ограничен по времени 20 минут!</p>

    </div>
  );
};

function PlayTest({ currQuestion, percentage, onOptionSelectionChange,
  checkAnswer, score, questionQuantity, step, userName, userJobTitle, testDate, onDone, time }) {

  return (
    <div className='playTest'>
      {step !== questionQuantity
        ? <Game
          currQuestion={currQuestion}
          percentage={percentage}
          onOptionSelectionChange={onOptionSelectionChange}
          checkAnswer={checkAnswer}
          onDone={onDone}
          time={time} />
        : <Result
          questionQuantity={questionQuantity}
          score={score}
          userName={userName}
          userJobTitle={userJobTitle}
          testDate={testDate} />
      }
    </div>
  )
}

function Game({ currQuestion, percentage, onOptionSelectionChange, checkAnswer, onDone, time }) {
  const { minutes } = useCountDown(onDone, time)
  return (
    <div className='game'>

      <div className='timer'>
        <h5>Осталось:<br /> {minutes} мин</h5>
      </div>

      <div className="progress">
        <div style={{ width: `${percentage}%` }} className="progress__inner"></div>
      </div>
      <h2>{currQuestion.text}</h2>
      <div className='game-modal'>
        <ul>
          {currQuestion.options.map((opt, index) => (
            <label key={opt.text}>
              <li>
                <input
                  id={opt.text}
                  type='checkbox'
                  checked={opt.isSelected}
                  onChange={(evt) => onOptionSelectionChange(evt.target.checked, index)} />
                {opt.text}
              </li>
            </label>
          ))}
        </ul>
      </div>
      <button onClick={checkAnswer}>Далее</button>
    </div>
  );
}

function Result({ score, questionQuantity, userName, userJobTitle, testDate }) {

  const testResult = (score) => {
    if (Math.ceil((score * 100) / questionQuantity) >= 85) {
      return 'Тест успешно пройден'
    } else {
      return 'Тест не пройден'
    }
  }

  return (
    <div className="result">
      <h2>Тест завершен </h2>
      <img src="https://cdn-icons-png.flaticon.com/512/2278/2278992.png" />
      <h3> {userJobTitle} - {userName} <br /> {score} верных ответа из {questionQuantity}</h3>
      <h3>{testResult(score)}</h3>
      <h4>Дата теста: {testDate} </h4>
    </div>
  );
}

function Modal({ active, setActive, img }) {
  return (
    <div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
      <div className='modal__content'>
        <img src={img} />
      </div>
    </div>
  );
}

function CorrectPopup({ active, setActive }) {
  return (
    <Modal active={active} setActive={setActive} img={corr} />
  );
}

function IncorrectPopup({ active, setActive }) {
  return (
    <Modal active={active} setActive={setActive} img={incorr} />
  );
}

function App() {

  const [quiz, setQuiz] = useState({
    questions: [
      // Завтраки 
      {
        text: "Тост с беконом",
        options: [
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Цезарь п/ф",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Скрембл (2 яйца,сливки 33%,молоко)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бекон ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Заправка с горчицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Глазунья из 2х яйиц",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "	Микрозелень",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Тост с Семгой и яйцом пашот",
        options: [
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка  с горчицей ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Чили сладкий",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо пашот",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Семга слабосоленая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус цезарь",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус устречный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Сырники сладкие",
        options: [
          {
            text: "Сырники п.ф( творог 5%, сахар, манная крупа, ванилин, яйцо, мука пшеничная)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана + Карамельный топинг ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шоколадный топинг",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Виноград",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Сырники с семгой",
        options: [
          {
            text: "Сырники",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Семга слабосаленая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка  с горчицей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус цезарь",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "ШАКШУКА",
        options: [
          {
            text: "Яйцо (глазунья из 2х яиц)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Помадоро",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Брынза сербская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Булочка белая",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Томатная паста",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Яйцо пашот",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук Фри",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Шпинатная сковородка",
        options: [
          {
            text: "Яйцо (2 яйца пашот)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шпинат",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло Чесночное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Овощная сальса",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Скрембл",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Булочка белая",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук красный карамелизованный",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Деревенский завтрак",
        options: [
          {
            text: "Яйцо (Глазунья из 1 яйца)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шампиньоны жареные п/ф",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук красный карамелизованный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Тесто на вафлю класическая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень в граммах",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук фри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Колбаска охотничья",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Помадоро",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Овощная сальса",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Яйцо пашот",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Овощная сальса",
        options: [
          {
            text: "Лук красный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Перец болгарский",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка бальзамическая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чеснок",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры черри",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Масло оливковое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      // Брускеты и ассорти
      {
        text: "Брускетта с печеными овощами (2шт)",
        options: [
          {
            text: "Батон мультизлаковый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сырный крем с Тахини",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Печёные овощи (баклажан, цукини, болгарский перец, шампиньоны, винный белый уксус, прованские травы, соль)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Брускетта с семгой (2шт)",
        options: [
          {
            text: "Батон мультизлаковый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Семга ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сырный крем с Тахини",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидор",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шампиньоны жареные",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Брускетта с грибами (2шт)",
        options: [
          {
            text: "Батон мультизлаковый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сырный крем с Тахини",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шампьоны жаренные",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук красный карамелизированный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр творожный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Семга ",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Сырное ассорти",
        options: [
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Бавария Блю",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр песто красный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мед",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Виноград",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Цукаты",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр сербская брынза",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Мясное ассорти",
        options: [
          {
            text: "Филе куриное копчёное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Говядина сырокопченая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пивчики Пражские",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Заправка с горчицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Колбаски охотничьи",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },


      //Салаты 
      {
        text: "Теплый салат с тигровыми креветками",
        options: [
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Свекла запеченая в меду",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсины",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка Бальзамическая ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Креветки тигровые",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста пекинская",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Творожный сыр с тахини",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Перец болгарский",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Салат с куриной печенью",
        options: [
          {
            text: "Печень Куриная",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Вино красное полусладкое",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Песто",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Орех кедровый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста пекинская",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Орех грецкий",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры черри",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук красный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Салат с печёными овощами",
        options: [
          {
            text: "Печёные овощи",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Филе куриное копчёное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сырный крем с тахини",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста пекинская",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Мясной салат с печеным картофелем",
        options: [
          {
            text: "Картофельные дольки печеные",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Говядина сырокопченая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста пекинская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо пашот",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка  с горчицей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Салат с говядиной и вялеными томатами ",
        options: [
          {
            text: "Капуста пекинская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Говядина сырокопченая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук красный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Перец болгарский",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры сушеные в масле",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка  с горчицей ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук фри",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Цезарь с курицей",
        options: [
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста Пекинская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо перепелиное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Филе куриное копчёное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухарики",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка с горчицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Цезарь с сёмгой",
        options: [
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста Пекинская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо перепелиное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Семга слабосоленая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухарики",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка с горчицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры сушеные в масле",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Цезарь с креветкой",
        options: [
          {
            text: "Салат Айсберг",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Капуста Пекинская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры Черри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо перепелиное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Креветка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухарики",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка с горчицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сырный крем с тахини ",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      //Супы
      {
        text: "Суп брокколи порц (1 пор)",
        options: [
          {
            text: "Крем суп брокколи п/ф: картофель, лук, корень сельдеря, капуста брокколи, сыр сливочный, овощной бульон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Крем суп брокколи п/ф:  картофель, лук, морковь, корень сельдеря, капуста брокколи, сыр сливочный, сливки, куриный бульон",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Крем суп брокколи п/ф: картофель, лук, корень сельдеря, капуста брокколи, сыр сливочный, молоко, овощной бульон",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Филе куриное копчёное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухарики п/ф",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень в граммах",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Суп грибной порц (1 пор)",
        options: [
          {
            text: "Крем суп из шампиньонов п/ф: картофель, лук, чеснок, шампиньоны, овощной бульон, молоко, сливки, грибная специя",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Крем суп из шампиньонов п/ф: картофель, лук, чеснок, шампиньоны, бульон куриный, молоко, сливки, грибная специя, паста карри",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Крем суп из шампиньонов п/ф: картофель, лук, чеснок, шампиньоны, овощной бульон, сливочный сыр, сливки, грибная специя",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сухарики п/ф",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Суп тыквенный порц (1 пор)",
        options: [
          {
            text: "Суп тыквенный п/ф: тыква, имбирь свежий, лук речатый, паста карри, овощной бульон, сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Суп тыквенный п/ф: тыква, имбирь свежий, лук речатый, паста Том Ям, овощной бульон, молоко",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Суп тыквенный п/ф: тыква, морковь, имбирь свежий, бульон куриный, лук речатый, паста карри, овощной бульон, сыр сливочный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сухарики п/ф",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      //Мидии + Жульен

      {
        text: "Жюльен",
        options: [
          {
            text: "Филе куриное копчёное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шампиньоны",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук репчатый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 33%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мука",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Чеснок",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Мидии в сливочном соусе ",
        options: [
          {
            text: "Мидии в панцире",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 33%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухой рыбный бульон Хондаши",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло Чесночное п/ф",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Булочка белая",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сухарики",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Мидии в соусе Том Ям",
        options: [
          {
            text: "Мидии в панцире",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 33%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Паста Том Ям",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Приправа рыбная Хандаши",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чиабатта",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Булочка белая",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сухарики",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Паста Карри",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      // Паста
      {
        text: "Ризотто с грибами ",
        options: [
          {
            text: "Рис ризотто : (рис арборио, лук, белое вино)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шампиньоны",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Грибы белые с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Грибной бульон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус Помодорро",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Паста Ризони с креветкой",
        options: [
          {
            text: "Паста Руммо (макароны румо, вино, лук)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Креветки тигровые",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухой рыбный бульон Хондаши",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус Помодорро",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Рис Ризотто",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ризотто с морепродуктами пор (1 пор)",
        options: [
          {
            text: "Масло Чесночное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Осминоги",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мидии",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кальмары",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сухой рыбный бульон Хондаши",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Помадоро ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Рис Ризотто ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Семга",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Креветки тигровые",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Паста карбонара",
        options: [
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Спагетти",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло растительное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Желток",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Орех кедровый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр Гауда",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Спагетти с копченой курицей и кедровыми орехами",
        options: [
          {
            text: "Спагетти",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Филе куриное копчёное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Песто",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Орех кедровый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр Пармезан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус Помодорро",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Орех грецкий",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      // Вафли
      {
        text: "Вафля с семгой",
        options: [
          {
            text: "Вафля бородинская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Семга",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук красный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка  с горчицей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо пашот",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус цезарь",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры черри",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Заправка бальзамическая",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вафля сырная",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Вафля с беконом и яйцом пашот",
        options: [
          {
            text: "Вафля сырная",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бекон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яйцо пашот",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Цезарь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Заправка бальзамическая",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Глазунья",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вафля чесночная",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Вафля с жюльеном",
        options: [
          {
            text: "Вафля чесночная",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Жюльен с курицей и грибами",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Вафля сырная",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вафля с корицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат Руккола",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Вафля венская",
        options: [
          {
            text: "Вафля венская",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мороженое сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Топинг карамельный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Топинг шоколадный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вафля с корицей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое малиновое с чиа",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Вафля  с яблоком",
        options: [
          {
            text: "Вафля с корицей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яблоки в вине",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мороженое сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус вишневый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вафля венская",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое шоколадное",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Вафля тыквенная",
        options: [
          {
            text: "Вафля тыквенная",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мороженое домашнее смородиновое",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус клюквенный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус вишневый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Яблоки в вине",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое сливочное",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вафля венская",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      //Ингридиенты
      {
        text: "Заправка  с горчицей",
        options: [
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Хрен",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Горчица",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чеснок",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус устречный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус соевый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Горчица дижонская",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Заправка Бальзамическая",
        options: [
          {
            text: "Масло оливковое",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло растительное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Уксус Бальзамический",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соль",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Смесь пяти перцев грамм",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус соевый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Уксус винный",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Соус Песто ",
        options: [
          {
            text: "Базилик",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло оливковое",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Орех кедровый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соль",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Петрушка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Орех грецкий",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус соевый",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Соус Цезарь",
        options: [
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Ворчестер",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Устричный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чеснок",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Уксус бальзамический",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Масло оливковое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус соевый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Прованские травы",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Тахини кунжутный",
        options: [
          {
            text: "Семя кунжутное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло растительное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соль",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Орех кедровый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр творожный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Уксус винный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Орех грецкий",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Лук красный карамелизованный",
        options: [
          {
            text: "Лук красный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соль",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Соевый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус устречный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Уксус винный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус сладкий чили",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук репчатый",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Масло Чесночное (сухарики)",
        options: [
          {
            text: "Масло растительное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чеснок",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Масло оливковое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук репчатый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лук красный",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Соус лава",
        options: [
          {
            text: "Икра Тобико",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сметана",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Морской Гребешок",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },


      // Роллы 
      {
        text: "Ролл Complimento с креветкой",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кунжут жареный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Креветка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус устричный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр с тахини",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Без творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Икра тобико",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Complimento с лососем",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кунжут жареный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лосось",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус устречный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр с тахини",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Без творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Икра тобико",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Сяки маки",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лосось",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сыр творожный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Икра тобико",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Complimento с курицей",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кунжут жареный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Копченая курица",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лук фри",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус устречный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр с тахини",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Икра тобико",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус цезарь",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Капа маки",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Помидор",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лосось",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Без творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Филе куриное копченое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Икра тобико",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Калифорния с лососем ",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Икра тобико",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лосось",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "огурец",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр с тахини",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Без творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Салат руккола",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Помидоры",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Лава с креветкой ",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус лава",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Креветка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус устречный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Без творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Ролл Лава с лососем",
        options: [
          {
            text: "Рис",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лист нори",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус лава",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лосось",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Микрозелень",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кунжут жареный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Огурец",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Без творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Майонез",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      // Десерты 
      {
        text: "Тирамису",
        options: [
          {
            text: "Крем на Тирамису",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Печенье Савоярди",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кофейная пропитка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Какао ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Корица",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Печенье топленое молоко",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Крем из творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Ром черный",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Какие вкусы Джелато есть в меню?",
        options: [
          {
            text: "Манго",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Смородина",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколад",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливочный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Малиновый с чиа",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Маракуйя",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Клубничный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Вишневый ",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Морковный торт (1 пор)",
        options: [
          {
            text: "Морковный бисквит",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Крем из творожного сыра",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Грушевое желе",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Декор из меренги",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Подложка из печенья",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Крем на тирамису",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шоколадная крошка декор",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Бисквит шоколадный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп маракуйя",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Шоколадный Фандан (1 пор)",
        options: [
          {
            text: "Тесто на шоколадный фандан",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсиновый декор",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мороженое сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Корица",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Апельсиновый конфитюр",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое смородиновое",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое шоколадное",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Штрудель с яблоком (1 пор)",
        options: [
          {
            text: "Яблоки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Орех грецкий",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мед",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Тесто слоеное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мороженое сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус Вишневый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Орех кедровый",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус клюквенный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Апельсиновый декор",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое шоколадное",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Торт трюфельный (1 пор)",
        options: [
          {
            text: "Корж на трюфельный торт",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколадный крем и глазурь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ром черный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Трюфельные конфеты",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколадный бисквит",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Крем из творожного сыра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Печенье савоярди",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп маракуйя",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Чизкейк (1 пор)",
        options: [
          {
            text: "Творожный сыр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп маракуйя",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Груша",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Подложка из печенья",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколадная крошка декор",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Яблоки",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп груша",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Печенье савоярди",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Ром черный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Декор из меренги",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Панна-Котта с ягодным конфитюром (1 пор)",
        options: [
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ягодный конфитюр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соус вишневый ",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Соус клюквенный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Кофейная пропитка",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Облепиховое желе",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шоколадная монетка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Крем-брюле (1 пор)	",
        options: [
          {
            text: "Крем-брюле (Желток, сливки, сахар, ванилин)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар обжигается",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколадная монетка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар не обжигается",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Ягодный конфитюр",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Декор из мренги",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шоколадная крошка декор",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Итальянский пирог с тыквой",
        options: [
          {
            text: "Итальянский пирог",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсиновый конфитюр",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мороженое сливочное",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколадная монетка",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарная пудра",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Ягодный конфитюр",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шоколадная крошка декор",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мороженое манго",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Панна-котта с облепиховым желе",
        options: [
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Семена Чиа",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Желе из облепихи",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Шоколадная крошка декор",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Голубика",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Шоколадная монетка",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

    ]
  });
  const [quizBar, setQuizBar] = useState({
    questions: [
      // Алк коктейли
      {
        text: "Космополитен",
        options: [
          {
            text: "Водка для коктейлей клюквенная",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер апельсиновый Куантро",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок лимона (Лимонный концетрат)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Морс Клюквенный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарный сироп",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсиновый декор",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Клюква с/м",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Пина Колада",
        options: [
          {
            text: "Ром для коктейлей белый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер кокосовый Малибу",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок \"Ананас\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки взбитые",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Кокосовый\"",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Лонг Айленд",
        options: [
          {
            text: "Водка для коктейлей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ром для коктейлей белый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер апельсиновый Куантро",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Текила для коктейлей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Джин для коктейлей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок лимона (Лимонный концетрат)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Кола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ром золотой",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Маргарита",
        options: [
          {
            text: "Текила для коктейлей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер апельсиновый Куантро",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок лимона (Лимонный концетрат)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Аква Минерале",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Фокси",
        options: [
          {
            text: "Сок \"Ананас\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Маракуйя\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ром Окхард",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ром белый для коктейлей",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Мохито классический АЛК",
        options: [
          {
            text: "Ром для коктейлей белый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахар тростниковый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарный сироп",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Аква минерале",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мартини бьянко",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп \"Мохито\"",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Мохито манговый АЛК",
        options: [
          {
            text: "Ром для коктейлей белый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пюре \"Манго\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Маракуйя\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Манго\" - 10мл",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Аква минерале",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мартини Бьянко",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп \"Мохито\"",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Текила Санрайз",
        options: [
          {
            text: "Текила для коктейлей",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок \"Апельсин\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Гренадин\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ром для коктейлей",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Апероль",
        options: [
          {
            text: "Вино игристое белое Ламбруско",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер Апероль",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Аква минерале",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсин (кружочком)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер Куантро",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Мартини бьянко",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Кокосовый бейлиз",
        options: [
          {
            text: "Ликер сливочный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Ликер кокосовый Малибу",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок \"Персик\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Гренадин\"",
            isSelected: false,
            isCorrect: true,
          }
        ]
      },

      // Чай 
      {
        text: "Чай \"Клубничная карамель\"",
        options: [
          {
            text: "Чай \"Гринфилд\" зеленый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Клубника\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Клубника с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Карамель\"",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Чай \"Тропический\"",
        options: [
          {
            text: "Чай \"Гринфилд\" зеленый",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пюре \"Манго\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пюре \"Маракуйя\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Карамель\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Кокос\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок \"Ананас\"",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп \"Манго\"",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Чай \"Вишневый\"",
        options: [
          {
            text: "Чай \"Гринфилд\" черный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Корица палочки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок \"Вишня\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Амаретто\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Вишня с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Вишня\"",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Чай \"Облепиховый\"",
        options: [
          {
            text: "Чай \"Гринфилд\" черный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Гвоздика",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Корица палочки",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок \"Апельсин\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Облепиха с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Чай \"Гринфилд\" зеленый",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Чай \"Имбирный\"",
        options: [
          {
            text: "Чай \"Гринфилд\" черный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Имбирь",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пюре \"Смородина\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарный сироп",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Гвоздика",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Чай \"Клюквенный\"",
        options: [
          {
            text: "Чай \"Гринфилд\" черный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лимон",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Клюква с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мед",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Морс клюквенный",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Корица палочки",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      //Кофе
      {
        text: "Флэт Уайт",
        options: [
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Эспрессо двойной ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Эспрессо одинарный",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Сырный латте",
        options: [
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сыр виола",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сахарный сироп",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Соль",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Эспрессо",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Раф \"Классический\"",
        options: [
          {
            text: "Эспрессо одинарный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп (на выбор гостя)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Ваниль\"",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Раф \"Апельсиновый\"",
        options: [
          {
            text: "Эспрессо одинарный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Ваниль\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Апельсин",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Апельсин\"",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Раф \"Сникерс\"",
        options: [
          {
            text: "Эспрессо  одинарный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Паста арахисовая",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Соленая карамель\"",
            isSelected: false,
            isCorrect: true,
          }
        ]
      },

      {
        text: "Раф \"С каенским перцем\"",
        options: [
          {
            text: "Эспрессо одинарный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Шоколад\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Перец каенский \"Чили\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп (на выбор гостя)",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Раф \"Малиновый\"",
        options: [
          {
            text: "Эспрессо одинарный",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Малина\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пюре \"Малина\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Малина сублимированная",
            isSelected: false,
            isCorrect: true,
          }
        ]
      },

      //Шоколадное
      {
        text: "Горячий шоколад",
        options: [
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Основа \"Горячий шоколад\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Бокал воды",
            isSelected: false,
            isCorrect: true,
          }
        ]
      },

      {
        text: "Какао с маршмеллоу",
        options: [
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Основа \"Горячий шоколад\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Зефир",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сливки",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      //Безалк
      {
        text: "Сливочный персик",
        options: [
          {
            text: "Сок \"Персиковый\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Молоко",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сливки 20%",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Ваниль\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Лимонный концетрат",
            isSelected: false,
            isCorrect: true,
          }
        ]
      },

      {
        text: "Лимонад \"Клубника маракуйя\"",
        options: [
          {
            text: "Пюре \"Маракуйя\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Cироп \"Клубника\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Маракуйя\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Клубника с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Аква минерале",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Манго\"",
            isSelected: false,
            isCorrect: false,
          }
        ]
      },

      {
        text: "Лимонад \"Ежевика Вишня\"",
        options: [
          {
            text: "Лайм",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сок лимона (Лимонный концетрат)",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Ежевика\" ",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Вишня с/м",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Аква минерале",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Мята",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Вишня\"",
            isSelected: false,
            isCorrect: false,
          },
        ]
      },

      {
        text: "Лимонад \"Бабл Гам\"",
        options: [
          {
            text: "Сироп \"Арбуз\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Банан\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Клубника\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Пюре  \"Малина\"",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Аква минерале",
            isSelected: false,
            isCorrect: true,
          },
          {
            text: "Сироп \"Бабл Гам\"",
            isSelected: false,
            isCorrect: false,
          },
          {
            text: "Сироп \"Малина\"",
            isSelected: false,
            isCorrect: false,
          },
        ]
      }
    ]
  });

  const [userName, setUserName] = useState('')
  const [userJobTitle, setUserJobTitle] = useState('')
  const [testDate, setTestDate] = useState('')
  const [testMode, setTestMode] = useState(false)
  const [step, setStep] = useState(0)
  const [score, setScore] = useState(0)
  const [currQuestion, setCurrQuestion] = useState(quiz.questions[step])
  const [modalActiveCorrect, setModalActiveCorrect] = useState(false)
  const [modalActiveIncorrect, setModalActiveIncorrect] = useState(false)
  const [time, setTime] = useState(60)
  const questionQuantity = quiz.questions.length
  const percentage = Math.round((step / quiz.questions.length) * 100)


  const onDone = () => {
    setStep(questionQuantity)
  }

  const onOptionSelectionChange = (value, index) => {
    const option = currQuestion.options[index]                            // создаем константу нажатого варианта ответа
    const cloneCurrQuestion = { ...currQuestion }                           // делаем полную копию текущего вопроса для дальнейшей возможности его изменения, не трогая истинный state
    cloneCurrQuestion.options.splice(index, 1, { ...option, isSelected: value })
    // в созданной коппи вопроса выбираем массив с вариантами. После чего указываем какой вариант был выбран с помощью index.
    // Далее удаляем данный вариант (т.к стоит 1) и вместо него вставляем константу выбранного варианта  и обновляем ему ключ isSelected
    setCurrQuestion(cloneCurrQuestion)                                    //применяем изменения в копированном текущем вопросе к истинному текущему вопросу
  }

  const checkAnswer = () => {
    const isCorrect = currQuestion.options.reduce((result, opt) => {
      return result && opt.isCorrect === opt.isSelected;
    }, true) // задаем переменной result значение true. Создаем функцияю которая возвращает true/false в зависимости от сравнения свойств и result

    setStep(step + 1)


    if (isCorrect) {
      setScore(score + 1)
      setModalActiveCorrect(true)
      setTimeout(() => {
        setModalActiveCorrect(false)
      }, 500);
    } else {
      setModalActiveIncorrect(true)
      setTimeout(() => {
        setModalActiveIncorrect(false)
      }, 500);
    }
  }

  const onUserNameChange = (name) => {
    setUserName(name)
  }
  const onUserJobTitle = (title) => {
    setUserJobTitle(title)
  }
  const onTestDateChange = (date) => {
    setTestDate(date)
  }
  function onTestModeChange() {
    let infoInputs = document.querySelectorAll('.n')


    if (infoInputs[0].value.length > 5) {
      infoInputs[0].isFulled = true // задаем новую переменную isFulled и даем true
    }
    if (infoInputs[1].value.length > 2) {
      infoInputs[1].isFulled = true
    }
    if (infoInputs[2].value.length != 0) {
      infoInputs[2].isFulled = true
    }
    if (infoInputs[0].isFulled && infoInputs[1].isFulled && infoInputs[2].isFulled === true) {
      setTestMode(true)
    } else {
      alert('Заполните поля правильно')
    }
  }
  const onTestModeChangeBar = () => {
    setTime(20);
    setQuiz(quizBar);
    onTestModeChange();
  }

  const shuffle = (arr) => { arr.sort(() => Math.random() - 0.5); }


  useEffect(() => {
    setTime(time)
    setQuiz(quiz)
    setModalActiveCorrect(modalActiveCorrect)
    setModalActiveIncorrect(modalActiveIncorrect)
    setCurrQuestion(quiz.questions[step]);
    if (step < questionQuantity) {
      shuffle(quiz.questions[step].options)
    }
  }, [step, quiz, time]);



  return (
    <div className="App">
      {!testMode
        ? <GreetingTest
          onTestModeChange={onTestModeChange}
          onUserNameChange={onUserNameChange}
          onUserJobTitle={onUserJobTitle}
          onTestDateChange={onTestDateChange}
          onTestModeChangeBar={onTestModeChangeBar} />

        : <PlayTest
          currQuestion={currQuestion}
          percentage={percentage}
          onOptionSelectionChange={onOptionSelectionChange}
          checkAnswer={checkAnswer}
          questionQuantity={questionQuantity}
          score={score}
          step={step}
          userName={userName}
          userJobTitle={userJobTitle}
          testDate={testDate}
          onDone={onDone}
          time={time} />
      }
      <CorrectPopup
        active={modalActiveCorrect}
        setActive={setModalActiveCorrect} />

      <IncorrectPopup
        active={modalActiveIncorrect}
        setActive={setModalActiveIncorrect} />

    </div>
  );
}


export default App;
