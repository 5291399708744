import { useEffect, useState } from "react"

export const useCountDown = (onDone, initialTime) => {

  const [hours, setHours] = useState(initialTime / 60)
  const [minutes, setMinutes] = useState(initialTime)
  const [seconds, setSeconds] = useState(0)
  const [timeout, _setTimeout] = useState()


  const countDownSeconds = () => {
    setSeconds((prev) => prev - 1)
  }
  const countDownMinutes = () => {
    setMinutes((prev) => prev - 1)
  }
  const countDownHours = () => {
    setHours((prev) => prev - 1)
  }

  const runTimer = () => {

    if (seconds === 0 && minutes === 0 && hours === 0) {
      return onDone()
    } if (seconds === 0 && minutes === 0) {
      setSeconds(59)
      setMinutes(60)
      countDownHours()
    } if (seconds === 0) {
      setSeconds(59)
      countDownMinutes()
    }

    setTimeout(
      () => {
        countDownSeconds()
      }, 1000)

  }




  useEffect(() => {
    runTimer()
  }, [seconds])



  return { hours, minutes, seconds }
}